* {
  padding: 0;
  margin: 0;
}

html,
body {
  overflow: hidden;
  /* height: 100%; */
  /* width: 100%; */
  height: 100vh;
  width: 100vw;
  position: relative;
  /* max-width: 1024px; */
  /* min-width: 768px; */
}

/* #root {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
} */

@media print {
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
    border-radius: 0;
    border-color: #000;
    box-shadow: none;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  div,
  div > a {
    color: #000;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    border-color: #000;
  }

  img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .float_print_container {
    display: none;
  }
}

@keyframes load {
  from {
    left: 20;
  }
  to {
    left: calc(100% - 100px);
  }
}

.placeholderA4::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 20%;
  background: linear-gradient(
    to right,
    transparent 0%,
    #f5f4f4 50%,
    transparent 100%
  );
  animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

button.print-button {
  width: 100px;
  height: 100px;
}

span.print-icon,
span.print-icon::before,
span.print-icon::after,
button.print-button:hover .print-icon::after {
  border: solid 4px #333;
}

span.print-icon::after {
  border-width: 2px;
}

button.print-button {
  position: relative;
  padding: 0;
  border: 0;

  border: none;
  background: transparent;
}

span.print-icon,
span.print-icon::before,
span.print-icon::after,
button.print-button:hover .print-icon::after {
  box-sizing: border-box;
  background-color: #fff;
}

span.print-icon {
  position: relative;
  display: inline-block;
  padding: 0;
  margin-top: 20%;

  width: 60%;
  height: 35%;
  background: #fff;
  border-radius: 20% 20% 0 0;
}

span.print-icon::before {
  content: ' ';
  position: absolute;
  bottom: 100%;
  left: 12%;
  right: 12%;
  height: 110%;

  transition: height 0.2s 0.15s;
}

span.print-icon::after {
  content: ' ';
  position: absolute;
  top: 55%;
  left: 12%;
  right: 12%;
  height: 0%;
  background: #fff;
  background-repeat: no-repeat;
  background-size: 70% 90%;
  background-position: center;
  background-image: linear-gradient(
    to top,
    #fff 0,
    #fff 14%,
    #333 14%,
    #333 28%,
    #fff 28%,
    #fff 42%,
    #333 42%,
    #333 56%,
    #fff 56%,
    #fff 70%,
    #333 70%,
    #333 84%,
    #fff 84%,
    #fff 100%
  );

  transition: height 0.2s, border-width 0s 0.2s, width 0s 0.2s;
}

button.print-button:hover {
  cursor: pointer;
}

button.print-button:hover .print-icon::before {
  height: 0px;
  transition: height 0.2s;
}

button.print-button:hover .print-icon::after {
  height: 120%;
  transition: height 0.2s 0.15s, border-width 0s 0.16s;
}

.float_print_container {
  position: fixed;
  width: 60px;
  height: 60px;
  top: 10px;
  left: 10px;
  text-align: center;
}
